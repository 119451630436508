<template>
  <div class="row">
    <loading
      v-model:active="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :color="color"
      loader="dots"
    />
    <div class="col-12 f13">
      <div class="card concard card-shadow">
        <div class="card-header white text-center">
          <img
            src="@/assets/logo.png"
            style="
              width: 100px;
              border-radius: 50%;
              box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
            "
          />
          <div class="t1" style="margin: 5px">ข้อมูลที่พักอาศัย</div>
        </div>
        <Form
          @submit="onSubmitRegister"
          :validation-schema="register"
          id="kt_account_profile_details_form"
          class="form"
          novalidate="novalidate"
        >
          <div class="card-body mb-47 mt-2 white">
            <!--begin::Card body-->
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <!--begin::Input group-->
                  <div class="row mb-6">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label required fw-bold fs-6"
                      >บ้านเลขที่</label
                    >
                    <!--end::Label-->

                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row">
                      <Field
                        type="text"
                        name="numberhome"
                        class="form-control form-control-solid"
                        placeholder="บ้านเลขที่"
                        v-model="data_register.numberhome"
                      />
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="numberhome" />
                        </div>
                      </div>
                    </div>
                    <!--end::Col-->
                  </div>
                  <!--end::Input group-->
                </div>
                <div class="col-6">
                  <!--begin::Input group-->
                  <div class="row mb-6">
                    <!--begin::Label-->
                    <label class="col-lg-4 col-form-label fw-bold fs-6">หมู่</label>
                    <!--end::Label-->

                    <!--begin::Col-->
                    <div class="col-lg-8 fv-row">
                      <Field
                        type="text"
                        name="moo"
                        class="form-control form-control-solid"
                        placeholder="หมู่"
                        v-model="data_register.moo"
                      />
                    </div>
                    <!--end::Col-->
                  </div>
                  <!--end::Input group-->
                </div>
              </div>

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6">ตรอก/ซอย</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    type="text"
                    name="alleyway"
                    class="form-control form-control-solid"
                    placeholder="ตรอก/ซอย"
                    v-model="data_register.alleyway"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="alleyway" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6">ถนน</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    type="text"
                    name="road"
                    class="form-control form-control-solid"
                    placeholder="ถนน"
                    v-model="data_register.road"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="road" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Card body-->
          </div>
          <div class="card-footer white">
            <div class="d-flex justify-center align-center">
              <div style="width: 100%; padding: 0px 5px">
                <button type="button" @click="back" class="button btn-back">
                  ย้อนกลับ
                </button>
              </div>
              <div style="width: 100%; padding: 0px 5px">
                <button type="submit" style="width: 100%" class="button">
                  ลงทะเบียน
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { defineComponent, ref, onMounted, inject } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import useRegister from "@/service/api/register";
import useSweetalert from "@/service/sweetalert2";
import liff from "@line/liff";
export default defineComponent({
  name: "Register",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading,
  },
  setup() {
    const store = inject("store");
    const router = inject("router");
    const { Sconfirm, SconfirmNc, SToast } = useSweetalert();
    const { Register } = useRegister();
    let isLoading = ref(false);
    let color = ref(process.env.VUE_APP_COLOR);
    const data_register = store.getters.getRegister;

    const register = Yup.object().shape({
      numberhome: Yup.string().required("กรุณากรอก บ้านเลขที่").label("บ้านเลขที่"),
      moo: Yup.string().label("หมู่"),
      alleyway: Yup.string().label("ตรอก/ซอย"),
      road: Yup.string().label("ถนน"),
    });

    const back = () => {
      router.push({ name: "Register" });
    };

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const onSubmitRegister = (values) => {
      store.dispatch(SET_REGISTER, values);

      Sconfirm("ยืนยัน การลงทะเบียน", "question").then(async (result) => {
        if (result.isConfirmed) {
          isLoading.value = true;

          await Register(store.getters.getRegister)
            .then((result) => {
              isLoading.value = false;
              if (result.data.status === "success") {
                SconfirmNc("ลงทะเบียนสำเร็จ", "success", "ตกลง").then(() => {

                  window.location.replace(`https://ref.smartsymptom.co/` + result.data.data.id);

                });
              } else {
                SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง").then(() => {
                  liff.ready.then(() => {
                    liff.closeWindow();
                  });
                });
              }
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data.errors;

                if (errors.id_line) {
                  SToast("error", `${errors.id_line[0]}`);
                } else {
                  SToast("error", "เกิดข้อผิดผาด กรุณาลองใหม่อีกครั้ง");
                }
              }
            });
        }
      });
    };

    return {
      back,
      onSubmitRegister,
      register,
      data_register,
      isLoading,
      color,
    };
  },
});
</script>

<style scoped>
.icon {
  width: 50px;
  padding: 5px;
}
.gender {
  padding: 30px 20px;
  border: 1px solid #dee2e6;
}
input[type="radio"] {
  display: none;
}
input:checked + span {
  color: var(--color);
  border: 2px solid var(--color);
}
</style>
