import ApiService from "@/service/ApiService";

export default function useLogin() {
  const CheckUser = async () => {
    ApiService.setHeader();
    return await ApiService.query("checkregister");
  };

  return {
    CheckUser,
  };
}
